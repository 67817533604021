import React from "react";
import Navbar from "../components/App/Navbar";
import Footer from "../components/App/Footer";
import Layout from "../components/App/Layout";
import AboutBanner from "../components/About/AboutBanner";
import MobileBanner from "../components/About/MobileBanner";
import AboutPage from "../components/About/AboutPage";

const About = () => {
  return (
    <Layout title={"About Hyfen8"}>
      <Navbar />
      <AboutBanner />
      <MobileBanner />
      <AboutPage />
      <Footer />
    </Layout>
  );
};

export default About;
