import React from "react";
import ReactWOW from "react-wow";

const aboutBanner = () => {
  return (
    <div className="about-pg-banner pg-banner">
      <div className="container  d-flex justify-content-md-end">
        <div className="about-banner-content desktop-banner-content">
          <ReactWOW delay=".1s" animation="fadeInLeft">
            <h1 className="heading-1">
              The Hyfen Eight<sup className="registered">&reg;</sup> Framework codifies intangibles and predicts enterprise value.
            </h1>

            <div className="py-4">
              <p className="text-1">Hyfen8 helps you identify and manage the intangible assets that drive exit value, growth and impact.</p>
            </div>
          </ReactWOW>
        </div>
      </div>
    </div>
  );
};

export default aboutBanner;
