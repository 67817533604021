import React from "react";
import ReactWOW from "react-wow";
const MobileBanner = () => {
  return (
    <div className="mobile-banner pt-5">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="about-pg-banner-content mobile-banner-content py-5">
              <ReactWOW delay=".1s" animation="fadeInLeft">
                <h1 className="heading-1">Make the invisible visible. Then harness its power.</h1>

                <div className="py-4">
                  <p className="text-1">Intangible assets can be a blind spot for businesses. They drive significant value – yet are often unaccounted for.</p>

                  <p className="text-4">
                    We developed a systemic framework to measure the powerful aspect of enterprise value, that connects your organisation with its potential and
                    drives up performance and sustainable growth – the idea of Hyfen8
                    <sup className="trademark">&trade;</sup> was born.
                  </p>
                </div>
              </ReactWOW>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileBanner;
